@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #0047ab;
  --primary-dark: "#003c99";
  --primary-light: "#0057b8";
  --accent: #343f7d;
  --secondary: #343434;
  --secondary-light: "#f0f0f0";
  --bg-gray: #f4f4f6;
  --family-poppins: "Poppins", sans-serif;

  --orange-custom: #e26913; /* define once for your project */
}
/* Hide next/prev arrows after 1 second */
.lg-prev,
.lg-next {
  animation: hideArrows 0.5s forwards;
  animation-delay: 1s;
}

@keyframes hideArrows {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    pointer-events: none; /* Disable interaction when hidden */
  }
}
.lg-counter {
  color: #fff !important; /* or any contrasting color */

  z-index: 99999; /* if something else is on top */
}
.lg-counter-current {
  color: #fff !important; /* or any contrasting color */

  z-index: 99999; /* if something else is on top */
}
.lg-counter-all {
  color: #fff !important; /* or any contrasting color */

  z-index: 99999; /* if something else is on top */
}
/* Optional: If the gallery is hovered, you may want to cancel the fade-out */
.lg:hover .lg-prev,
.lg:hover .lg-next {
  animation: none;
  opacity: 1;
  pointer-events: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: var(--family-poppins) !important;
  color: #000000;
}

body {
  font-family: var(--family-poppins);
  overflow-x: hidden;
  background: var(--bg-gray);
}

main {
  min-height: calc(100vh - 84px - 81px);
}

a {
  text-decoration: none;
  color: initial;
}

textarea,
button,
input {
  outline: none;
}

button:disabled {
  opacity: 0.5;
}
.loading-dots .dot {
  animation: blink 1.4s infinite both;
}
.loading-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.blog-content-scrollbar::-webkit-scrollbar {
  display: block;
  width: 8px;
}
.blog-content-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.blog-content-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
.blog-content-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.blog-content a {
  color: inherit; /* Default link color */
  text-decoration: none;
}

.blog-content a:hover,
.blog-content a:hover * {
  color: var(
    --primary
  ) !important; /* Turn both the link and any text within it blue when hovered */
  text-decoration: underline; /* Optionally underline the link */
}
*::placeholder {
  color: gray !important;
}
.multi_select__control {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
}
.multi_select .dropdown-heading .clear-selected-button {
  display: none !important;
}
.auction-multi-select .dropdown-content {
  width: 100%; /* Full width on mobile */
  max-width: 400px; /* Restrict max width */
  position: absolute;
  left: 0;
  right: 0; /* Center dropdown */
  z-index: 50;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

body.stop-scrolling {
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  main {
    min-height: calc(100vh - 55px - 81px);
  }
}

/* react multi select  */
.dropdown-container {
  border-color: var(--primary) !important;
  border-radius: 6px !important;
  outline: none !important;
}
.tooltip-tail {
  position: absolute;
  top: -10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--primary); /* The white part of the tooltip tail */
  z-index: 50; /* On top of the border */
}
.tooltip-tail-orange {
  position: absolute;
  top: -10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(234, 88, 12); /* The white part of the tooltip tail */
  z-index: 50; /* On top of the border */
}
.comic-tooltip::after {
  content: "";
  position: absolute;
  top: -12px; /* Slightly above the tooltip tail */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--primary); /* Border color */
  z-index: 49; /* Behind the tooltip tail */
}

.dropdown-heading {
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 650px) {
  .dropdown-heading {
    justify-content: normal;
  }
}
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

@media screen and (min-width: 300px) {
  .dropdown-heading-value {
    max-width: 131px;
  }
  .zoom-container {
    position: relative;
    overflow: hidden;
    cursor: url("./assets/zoom-in-svgrepo-com.svg");
    object-fit: contain;
  }

  .zoomed-image {
    position: relative;
  }

  .zoomed-in {
    transform: scale(3);
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
  }
  .wide-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .main-photo-container {
    width: 80%;
    height: auto;
    aspect-ratio: auto;
    overflow: hidden;
  }

  .main-photo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .collectibles-numismatics-coins-banknotes {
    display: none;
  }
  .collection-container {
    max-height: 540px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .zoom-container {
    position: relative;
    min-width: 100% !important;

    width: 50%; /* This ensures the container is responsive */
    height: 60%; /* This ensures the container is responsive */
    overflow: hidden;
    object-fit: contain;
  }

  .zoomed-image {
    object-fit: contain;
  }

  .zoomed-in {
    transform: scale(4); /* Adjust scale value as per your need */
  }
  .sold-ribbon-overlay {
    position: absolute;
    top: 0;
    /* left: 80.5%;*/
    width: 50%; /* Full width of the image */
    height: 50%; /* Full height of the image */
    pointer-events: none;
  }

  .sold-ribbon-overlay svg {
    width: 50%;
    height: 50%;
  }
  .rounded-upper-left {
    clip-path: polygon(0% 0%, 90% 0%, 100% 10%, 100% 100%, 0% 100%);
  }
  [dir="rtl"] .sold-ribbon-overlay {
    left: auto;
    right: 76.5%; /* Adjust for RTL */
  }
  @media (max-width: 700px) {
    .sold-ribbon-overlay {
      position: absolute;
      top: 0;
      /*left: calc(80% + ((700px - 100vw) / 40) * 1.01);*/
      width: 50%; /* Full width of the image */
      height: 50%; /* Full height of the image */
      pointer-events: none;
    }
    [dir="rtl"] .sold-ribbon-overlay {
      left: auto;
      /*right: calc(80% + ((700px - 100vw) / 40) * 1.01);*/
    }
  }

  .zoom-container.zoomed-in {
    cursor: url("./assets/zoom-out.svg");
  }
  .transitioning {
    pointer-events: none;
  }
  .unselectable {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
  }
  .zoom-container img {
    /* Absolutely position the image inside the container */
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image fits within the square while maintaining aspect ratio */
  }
  .collection-container > * {
    max-height: 100%;
    overflow: hidden;
  }
  .scrollable-container {
    scrollbar-width: thin; /* For Firefox */
    overflow-x: auto; /* For horizontal scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
  }

  .scrollable-container::-webkit-scrollbar {
    height: 8px; /* Scrollbar height for WebKit browsers */
  }

  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Scrollbar color */
    border-radius: 10px; /* Round edges */
  }
}
@media (max-width: 750px) {
  .collection-container {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .zoom-container {
    position: relative;

    width: 50%; /* This ensures the container is responsive */
    height: 50%; /* This ensures the container is responsive */
    overflow: hidden;
    object-fit: contain;
  }
}
